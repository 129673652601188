import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Select,
  Modal,
  Box,
  Grid,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import { LoadingButton } from '@mui/lab';
// components
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Label from '../components/label';
import Iconify from '../components/iconify';
import { UserListHead, UserListToolbar, CustomersToolbar } from '../sections/@dashboard/user';
import TarsService from "../services/TarsService"
import NewInvitation from './NewInvitation';
import useResponsive from '../hooks/useResponsive';
import BellService from "../services/BellService"
import { useGlobalProvider } from '../components/GlobalProvider'
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import TransgenderIcon from '@mui/icons-material/Transgender';
import NewCustomerModal from './NewCustomerModal';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import VerifiedIcon from '@mui/icons-material/Verified';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'LabelDocument', label: 'Documento', alignRight: false },

  { id: 'FullName', label: 'Nombre', alignRight: false },
  { id: 'LabelAge', label: 'Genero/Edad', alignRight: false },
  { id: 'Phone', label: 'Celular', alignRight: false },
  { id: 'Email', label: 'Correo', alignRight: false },
  { id: 'LabelStatus', label: 'Estado', alignRight: false },
  { id: 'LabelCurrentCreditLimit', label: 'Cupo', alignRight: false },
  { id: 'LabelCreditLimitResult', label: 'Novedad Cupo', alignRight: false },
  { id: 'LabelDateCreatedFull', label: 'Fecha Creación', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------



function descendingComparator(a, b, orderBy) {

  if (orderBy === 'LabelDateCreatedFull') {

    if (dayjs(b[orderBy]) < dayjs(a[orderBy])) {
      return -1;
    }
    if (dayjs(b[orderBy]) > dayjs(a[orderBy])) {
      return 1;
    }
    // return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {

    // return filter(array, (_user) => _user.Document.indexOf(query) !== -1); // por documento
    return filter(array, (_user) =>
      (_user.FullName ? _user.FullName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
      || _user.LabelDocument.indexOf(query) !== -1
      // || (_user.LabelStatus ? _user.LabelStatus.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
      // || (_user.Reference ? _user.Reference.toLowerCase().indexOf(query.toLowerCase()) !== -1 : false)
      // || (_user.LabelDateCreated ? _user.LabelDateCreated.indexOf(query) !== -1 : false)
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomersPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('LabelDateCreatedFull');

  const [isUpdate, setIsUpdate] = useState(false);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);


  const [snackbarData, setSnackbarData] = useState({ message: "msg", severity: "warning" });

  const [errors, setErrors] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const { GetCustomers } = TarsService();

  const mdUp = useResponsive('up', 'md');
  const { posData, treatments, documentTypes, notificationTypes, invitationsStatus, sending, setSending, countries } = useGlobalProvider();
  const [customers, setCustomers] = useState([]);
  const [openModalNC, setOpenModalNC] = useState(false);

  useEffect(() => {

    setSending(true)
    GetCustomers().then(res => {
      setSending(false)

      if (res.status === 200) {
        let data = JSON.parse(res.data.data)


        setCustomers(data ?? [])
      }
      else {

      }

    })

  }, []);





  const handleOpenMenu = (event, row) => {


  };





  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;

  const filteredUsers = applySortFilter(customers, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;


  const [openSnackbar, setOpenSnackbar] = useState(false);


  const smUp = useResponsive('up', 'sm');



  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };
  const iconStyleE = {
    width: 16,
    height: 16,
    '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
  };
  const iconStyleS = {
    width: 20,
    height: 20,
    '& svg, img': { width: 1, height: 1, objectFit: 'cover' },
  };
  return (
    <>
      <NewCustomerModal
        openModalNC={openModalNC}
        setOpenModalNC={setOpenModalNC}
      />

      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
          {snackbarData.message}
        </Alert>
      </Snackbar>



      <Helmet>
        <title> Clientes | Aliados Meddipay </title>
      </Helmet>

      <Container style={{ maxWidth: "unset" }}>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom />
          {posData && <Button variant="outlined" startIcon={<Iconify icon="eva:person-add-fill" />} onClick={() => { setOpenModalNC(true) }}>
            Nuevo cliente
          </Button>}
        </Stack>

        <Card id="card-ip">

          <CustomersToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} customers={customers} />

          <Card style={{ borderRadius: "0" }} >
            <TableContainer style={{ width: mdUp ? "100%" : "99%", margin: "0 auto" }} className={mdUp ? undefined : "main-container-ip"}>
              <Table >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={customers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}

                />
                <TableBody >
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                    const { IdCustomer, LabelDocument, FullName, LabelDateCreated, LabelStatus, LabelCurrentCreditLimit, Status,
                      LabelCreditLimitResultColor, LabelStatusColor, Phone, Email, LabelCreditLimitResult, LabelAge, LabelCreditLimitColor, Gender,
                      LabelTimeCreated, ValidPhone, ValidEmail } = row;

                    const selectedUser = selected.indexOf(Document) !== -1;

                    return (
                      <TableRow hover key={IdCustomer} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, document)} />
                        </TableCell> */}


                        <TableCell align="left">{LabelDocument}</TableCell>

                        <TableCell align="left" >


                          <Tooltip title={FullName}>



                            <span>

                              {FullName && FullName.length > 25 ? `${FullName.slice(0, 25)}...` : (FullName ?? "---")}

                            </span>


                          </Tooltip>



                        </TableCell>


                        <TableCell align="left">
                          <Tooltip title={Gender === "FE" ? "Femenino" : Gender === "MA" ? "Masculino" : "Otro"}>
                            {/* <Label startIcon={Gender === "FE" ? <FemaleIcon /> : Gender === "MA" ? <MaleIcon /> : <PanoramaFishEyeIcon />}>{LabelAge}</Label> */}
                            <span style={{ display: "inline-flex", alignItems: "center" }}> {Gender && <Box sx={{ ml: 0.75, ...iconStyleS }}> {Gender === "FE" ? <FemaleIcon /> : Gender === "MA" ? <MaleIcon /> : <PanoramaFishEyeIcon />} </Box>} {LabelAge} </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="left">
                          {/* {Phone && <Label endIcon={ValidPhone ? <VerifiedIcon color='success' /> : <></>}> {Phone}</Label>} */}
                          {/* {Phone}<VerifiedIcon color='success' /> */}
                          {Phone && <span style={{ display: "inline-flex", alignItems: "center" }}>  {Phone} {ValidPhone && <Box sx={{ ml: 0.75, ...iconStyleE }}> <VerifiedIcon color='success' />  </Box>}</span>}
                        </TableCell>

                        <TableCell align="left">
                          {/* {Email && <Label endIcon={ValidEmail ? <VerifiedIcon color='success' /> : <></>}>    {Email}</Label>} */}

                          {Email && <span style={{ display: "inline-flex", alignItems: "center" }}>  {Email} {ValidEmail && <Box sx={{ ml: 0.75, ...iconStyleE }}> <VerifiedIcon color='success' />  </Box>}</span>}
                        </TableCell>


                        <TableCell align="left">
                          <Label color={LabelStatusColor}>{LabelStatus}</Label>
                        </TableCell>



                        <TableCell align="left">
                          <Label color={LabelCreditLimitColor}  >{LabelCurrentCreditLimit ?? "N/A"}</Label>
                        </TableCell>

                        <TableCell align="left">
                          <Label color={LabelCreditLimitResultColor} >{LabelCreditLimitResult}</Label>
                        </TableCell>






                        {/* <TableCell align="left" style={{ minWidth: "110px" }}>{dayjs(LabelDateCreated).format("DD-MM-YYYY")}</TableCell> */}

                        <TableCell align="left">
                          <Tooltip title={LabelTimeCreated}>
                            {/* {LabelDateCreated} */}
                            <span>   {dayjs(LabelDateCreated).format("DD-MM-YYYY")}</span>

                          </Tooltip>
                        </TableCell>


                        <TableCell align="right">

                          <IconButton size="large" color="inherit" onClick={(event) => { handleOpenMenu(event, row) }}>
                            <Iconify icon={'eva:more-vertical-fill'} color={"black"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No encontrada
                          </Typography>

                          <Typography variant="body2">
                            Sin resultados para &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br />
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {sending &&

                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <CircularProgress color="primary" />
                      </TableCell>
                    </TableRow>
                  </TableBody>}
              </Table>




            </TableContainer>
          </Card>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
          />
        </Card>

      </Container >





    </>
  );
}
